import React, { useState } from "react";
import { Button, SelectCountry, TextInput } from "components/input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import RoutePath from "router/route_path";
import { joinWaitingList } from "api/api";
import { SIconTick } from "assets/icons";

const schema = yup.object().shape({
  name: yup.string().required().min(1),
  email: yup.string().required().email(),
  country: yup.string(),
});

const WaitListForm = ({ handleClose, setIsError, handleOpenStatusModal }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: "Nigeria",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasAcceptedPrivacy, sethasAcceptedPrivacy] = useState(false);
  const [hasAcceptedPromotional, sethasAcceptedPromotional] = useState(false);

  const handleChangeCountry = (e) => {
    // // console.log(e.target.value);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await joinWaitingList(data);
      // // console.log(data, data);
      if (response.data.status === "success") {
        setIsLoading(false);
        setIsError(false);
        handleClose();
        handleOpenStatusModal();
        // // console.log("success");
      } else {
        setIsLoading(false);
        setIsError(true);
        handleClose();
        handleOpenStatusModal();
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      handleClose();
      handleOpenStatusModal();
    }
  };

  return (
    <form
      onSubmit={
        hasAcceptedPrivacy && hasAcceptedPromotional
          ? handleSubmit(onSubmit)
          : (e) => {
              e.preventDefault();
            }
      }
    >
      <div className="space-y-6">
        <TextInput
          label="First name"
          name="name"
          id="name"
          placeholder="Enter your first name"
          {...{ register, errors }}
        />
        <TextInput
          label="Email"
          name="email"
          id="email"
          placeholder="Enter your email"
          {...{ register, errors }}
        />

        <Controller
          name="country"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectCountry
              handleChangeCountry={handleChangeCountry}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>

      <div className="pt-6 space-y-3">
        <CheckBoxWitText
          onClick={() => sethasAcceptedPrivacy((prev) => !prev)}
          isActive={hasAcceptedPrivacy}
          textWidget={
            <p className="">
              I have read and agree to Skitpay.com's{" "}
              <Link
                className="text-sprimary underline"
                to={RoutePath.privacyPolicy}
                target="_blank"
              >
                Privacy Policy and Terms of Use{" "}
              </Link>
            </p>
          }
        />
        <CheckBoxWitText
          onClick={() => sethasAcceptedPromotional((prev) => !prev)}
          isActive={hasAcceptedPromotional}
          textWidget={
            <p className="">
              Yes, I consent to receive{" "}
              <span className="font-bold">
                promotional and marketing offers{" "}
              </span>
              from Skitpay Inc. and all its subsidiaries.
            </p>
          }
        />
      </div>

      <div className="md:flex md:mt-10">
        <Button
          title="JOIN THE WAITLIST"
          className={`border  mt-16 md:mt-0 shadow-[0_1px_2px_rgba(16,24,50,0.05)] w-[202px] mx-auto flex items-center justify-center ${
            !(hasAcceptedPrivacy && hasAcceptedPromotional)
              ? "bg-sgrey-300 text-sgrey-500"
              : "btn-bg border-[#670DBE]"
          }`}
          {...{ isLoading }}
        />
        <Button
          className="text-sm myriad-semibold border border-[#D0D0D0] shadow-[0_1px_2px_rgba(16,24,50,0.05)] text-sgrey-100 w-[202px] mx-auto mt-4 md:mt-0 flex items-center justify-center"
          title="CANCEL"
          onClick={handleClose}
        />
      </div>
    </form>
  );
};

export default WaitListForm;

const CheckBoxWitText = ({ textWidget, onClick, isActive = true }) => {
  return (
    <div className="flex space-x-4 ">
      <div
        className="h-[18px] w-[18px] rounded-full p-1   border border-sprimary cursor-pointer relative"
        onClick={onClick}
      >
        <AnimatePresence>
          {isActive && (
            <motion.span
              initial={{ rotateZ: 360, opacity: 0 }}
              animate={{ rotateZ: 0, opacity: 1 }}
              exit={{ rotateZ: -180, opacity: 0 }}
              className="text-sprimary absolute   right-[-10%]"
            >
              <div className="relative grid place-items-center bg-red-700 w-[12px]">
                <SIconTick className="text-white absolute stroke-[5]" />
                <SIconTick className="absolute  " />
              </div>
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      <div className="w-[90%] text-xs ">{textWidget}</div>
    </div>
  );
};
