import RoutePath from "router/route_path";

export const termsOfUse = [
  {
    heading: "1. Access",
    contents: [
      {
        title: "▪ Account Creation",
        paragraphs: [
          `In order to use certain features of the Site, you must register for an account (“Account”) and provide certain information about yourself as prompted by the account registration form.  You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by following the instructions on the Site.  Company may suspend or terminate your Account in accordance with Section 7. 
          
            `,
        ],
      },
      {
        title: "▪ Account Responsibilities. ",
        paragraphs: [
          `You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account.  You agree to immediately notify Company of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security.  Company cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.
          `,
        ],
      },
    ],
  },
  {
    heading: "2. Access to the Site",
    contents: [
      {
        title: "▪ License.",
        paragraphs: [
          `Subject to these Terms, Company grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site solely for your own personal, noncommercial use.  
            `,
        ],
      },
      {
        title: "▪ Certain Restrictions.",
        paragraphs: [
          `The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.  Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.  All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.
          `,
        ],
      },

      {
        title: "▪ Modification.",
        paragraphs: [
          `Company reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you.  You agree that Company will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof. 
          `,
        ],
      },
      {
        title: "▪ No Support or Maintenance.",
        paragraphs: [
          `You acknowledge and agree that Company will have no obligation to provide you with any support or maintenance in connection with the Site. 
          `,
        ],
      },
      {
        title: "▪ Ownership.",
        paragraphs: [
          `You acknowledge that all the intellectual property rights, including copyrights, patents, trade marks, and trade secrets, in the Site and its content are owned by Company or Company’s suppliers.  Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 2.1. Company and its suppliers reserve all rights not granted in these Terms.  There are no implied licenses granted under these Terms.
          `,
        ],
      },
      {
        title: "▪ Feedback.",
        paragraphs: [
          `If you provide Company with any feedback or suggestions regarding the Site (“Feedback”), you hereby assign to Company all rights in such Feedback and agree that Company shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate.  Company will treat any Feedback you provide to Company as non-confidential and non-proprietary.  You agree that you will not submit to Company any information or ideas that you consider to be confidential or proprietary.
          `,
        ],
      },
    ],
  },
  {
    heading: "3. Indemnification.",
    introText:
      "You agree to indemnify and hold Company (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Site, (b) your violation of these Terms or (c) your violation of applicable laws or regulations.  Company reserves the right, at your expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defence of these claims.  You agree not to settle any matter without the prior written consent of Company.  Company will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.",
  },
  {
    heading: "4. Third-Party Links & Ads; Other Users",
    contents: [
      {
        title: "▪ Third-Party Links & Ads.",
        paragraphs: [
          `The Site may contain links to third-party websites and services, and/or display advertisements for third parties (collectively, “Third-Party Links & Ads”).  Such Third-Party Links & Ads are not under the control of Company, and Company is not responsible for any Third-Party Links & Ads.  Company provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links & Ads.  You use all Third-Party Links & Ads at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.  You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links & Ads.
            `,
        ],
      },
      {
        title: "▪ Other Users.",
        paragraphs: [
          `Your interactions with other Site users are solely between you and such users.  You agree that Company will not be responsible for any loss or damage incurred as the result of any such interactions.  If there is a dispute between you and any Site user, we are under no obligation to become involved.
            `,
        ],
      },
      {
        title: "▪ Release.",
        paragraphs: [
          `You hereby release and forever discharge the Company (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site (including any interactions with, or act or omission of, other Site users or any Third-Party Links & Ads).  if you are a california resident, you hereby waive california civil code section 1542 in connection with the foregoing, which states: “a general release does not extend to claims which the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor or released party.”
            `,
        ],
      },
    ],
  },
  {
    heading: "5. Disclaimers",
    contents: [
      {
        paragraphs: [
          `
        The site is provided on an “as-is” and “as available” basis, and company (and our suppliers) expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.  we (and our suppliers) make no guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe.  If applicable law requires any warranties with respect to the site, all such warranties are limited in duration to ninety (90) days from the date of first use.`,

          `some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.  Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you.
        `,
        ],
      },
    ],
  },
  {
    heading: "6. Limitation on Liability",
    contents: [
      {
        paragraphs: [
          `
          To the maximum extent permitted by law, in no event shall company (or our suppliers) be liable to you or any third party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or inability to use, the site, even if company has been advised of the possibility of such damages.  access to, and use of, the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.
          `,

          `to the maximum extent permitted by law, notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or related to these terms (for any cause whatsoever and regardless of the form of the action), will at all times be limited to a maximum of fifty us dollars (u.s. $50). the existence of more than one claim will not enlarge this limit.  you agree that our suppliers will have no liability of any kind arising from or relating to these terms.
          
        `,
          `Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.
        `,
        ],
      },
    ],
  },
  {
    heading: "7. Term and Termination",
    introText:
      "Subject to this Section, these Terms will remain in full force and effect while you use the Site.  We may suspend or terminate your rights to use the Site (including your Account) at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.  Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately.  Company will not have any liability whatsoever to you for any termination of your rights under these Terms, including for termination of your Account.  Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2.2 through 2.6 and Sections 3 through 8.",
  },
  {
    heading: "8. General",
    contents: [
      {
        title: "1. Changes.",
        paragraphs: [
          `These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on our Site.  You are responsible for providing us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.  Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.
                `,
        ],
      },
      {
        title:
          "2. Dispute Resolution. Please read this Section 8.2 (sometimes referred to herein as this “Arbitration Agreement”) carefully.  It is part of your contract with Company and affects your rights.  It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER..",
      },
      {
        title: "▪ Applicability of Arbitration Agreement.",
        paragraphs: [
          `All claims and disputes (excluding claims for injunctive or other equitable relief as set forth below) in connection with these Terms or the use of any product or service provided by the Company that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement.  Unless otherwise agreed to, all arbitration proceedings shall be held in English.  This Arbitration Agreement applies to you and the Company, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under these Terms.
            `,
        ],
      },
      {
        title: "▪ Notice Requirement and Informal Dispute Resolution.",
        paragraphs: [
          `Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute (“Notice”) describing the nature and basis of the claim or dispute, and the requested relief.  A Notice to the Company should be sent to: 651 N Broad St, Middletown, Delaware, Delaware 19709, US, Middletown, Delaware 19709.  After the Notice is received, you and the Company may attempt to resolve the claim or dispute informally.  If you and the Company do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding.  The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled.
            `,
        ],
      },
      {
        title: "▪ Arbitration Rules.",
        paragraphs: [
          `The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.  The arbitration will be conducted by JAMS, an established alternative dispute resolution provider.  Disputes involving claims, counterclaims, or request for relief under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at @spLink@?type=external?params={"to": "http://www.jamsadr.com/rules-streamlined-arbitration/"}http://www.jamsadr.com/rules-streamlined-arbitration/@spLink@; all other disputes shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at @spLink@?params={"to":"${RoutePath.jamsadrRules}", "target":"_blank"}http://www.jamsadr.com/rules-comprehensive-arbitration/@spLink@.  JAMS’s rules are also available at www.jamsadr.com or by calling JAMS at 800-352-5267.  If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum.  Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.  You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the country where you live or at another mutually agreed location.  Any judgment on the    award rendered by the arbitrator may be entered in any court of competent jurisdiction.
            `,
        ],
      },
      {
        title: "▪ Authority of Arbitrator.",
        paragraphs: [
          `The arbitrator shall have exclusive authority to (i) determine the scope and enforceability of this Arbitration Agreement and (ii) resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to, any assertion that all or any part of this Arbitration Agreement is void or voidable.  The arbitration will decide the rights and liabilities, if any, of you and Company.  The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim. The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and these Terms (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and us.
            `,
        ],
      },
      {
        title: "▪ Waiver of Jury Trial.",
        paragraphs: [
          `YOU AND COMPANY HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY.  You and Company are instead electing that all disputes, claims, or requests for relief shall be resolved by arbitration under this Arbitration Agreement, except as specified in Section 8.2(a) (Applicability of Arbitration Agreement) above.  An arbitrator can award on an individual basis the same damages and relief as a court and must follow these Terms as a court would.  However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.
            `,
        ],
      },
      {
        title: "▪ Waiver of Class or Other Non-Individualized Relief",
        paragraphs: [
          `ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.  If a decision is issued stating that applicable law precludes enforcement of any of this section’s limitations as to a given dispute, claim, or request for relief, then such aspect must be severed from the arbitration and brought into the State or Federal Courts located in the State of Delaware.  All other disputes, claims, or requests for relief shall be arbitrated.
            `,
        ],
      },
      {
        title: "▪ 30-Day Right to Opt Out.",
        paragraphs: [
          `You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt out to: userpolicies@skitpay.com, within thirty (30) days after first becoming subject to this Arbitration Agreement.  Your notice must include your name and address, your Company username (if any), the email address you used to set up your Company account (if you have one), and an unequivocal statement that you want to opt out of this Arbitration Agreement.  If you opt out of this Arbitration Agreement, all other parts of these Terms will continue to apply to you.  Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.
            `,
        ],
      },
      {
        title: "▪ Severability.",
        paragraphs: [
          `Except as provided in Section 8.2(f) (Waiver of Class or Other Non-Individualized Relief), if any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect.
            `,
        ],
      },
      {
        title: "▪ Survival of Agreement.",
        paragraphs: [
          `This Arbitration Agreement will survive the termination of your relationship with the Company.
            `,
        ],
      },
      {
        title: "▪ Modification.",
        paragraphs: [
          `Notwithstanding any provision in these Terms to the contrary, we agree that if Company makes any future material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such change becoming effective by writing Company at the following address: userpolicies@skitpay.com.
            `,
        ],
      },
      {
        title: "3. Export.",
        paragraphs: [
          `The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, reexport, or transfer, directly or indirectly, any U.S. technical data acquired from Company, or any products utilizing such data, in violation of the United States export laws or regulations. 
            `,
        ],
      },
      {
        title: "4. Disclosures.",
        paragraphs: [
          `Company is located at the address in Section 8.8. If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
            `,
        ],
      },
      {
        title: "5. Electronic Communications.",
        paragraphs: [
          `The communications between you and Company use electronic means, whether you use the Site or send us emails, or whether Company posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal requirement that such communications would satisfy if it were be in a hardcopy writing. The foregoing does not affect your non-waivable rights.
            `,
        ],
      },
      {
        title: "6. Entire Terms.",
        paragraphs: [
          `These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation”.  If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.  Your relationship to Company is that of an independent contractor, and neither party is an agent or partner of the other.  These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.  Company may freely assign these Terms.  The terms and conditions set forth in these Terms shall be binding upon assignees. 
            `,
        ],
      },
      {
        title: "7. Copyright/Trademark Information.",
        paragraphs: [
          `Copyright © 2023 Skitpay Inc. All rights reserved.  All trademarks, logos and service marks (“Marks”) displayed on the Site are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.
            `,
        ],
      },
      {
        title: "8. Contact Information:",
        paragraphs: [
          `▪ Address: 
            `,
          `▪ 651 N Broad St, Middletown, Delaware, Delaware 19709, US
            `,
          `▪ Middletown, Delaware 19709
            `,
          `▪ Telephone: +2348134029602
            `,
          `▪ Email: @spLink@?params={"to": "#"}info@skitpay.com@spLink@`,
        ],
      },
    ],
  },
];
