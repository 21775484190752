export const IconsArrorUpperRight = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.823242 9.1084L8.64576 1.28589M8.64576 1.28589H0.823242M8.64576 1.28589V9.1084"
        stroke="white"
        strokeWidth="1.5645"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconTwitter = () => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.63466 12.9734C11.2419 12.9734 14.3097 8.32678 14.3097 4.29844C14.3097 4.16781 14.3067 4.03428 14.3009 3.90365C14.8977 3.47207 15.4127 2.9375 15.8218 2.32506C15.266 2.57234 14.6759 2.73384 14.0716 2.80403C14.7078 2.42266 15.1842 1.82355 15.4125 1.11775C14.8139 1.47247 14.1593 1.72269 13.4768 1.85769C13.0169 1.36905 12.4089 1.04551 11.7467 0.937092C11.0845 0.828677 10.405 0.941427 9.81333 1.25791C9.22163 1.57439 8.75067 2.07698 8.47326 2.68796C8.19585 3.29895 8.12745 3.98431 8.27861 4.63808C7.06665 4.57726 5.881 4.26242 4.79852 3.71398C3.71605 3.16554 2.76091 2.39574 1.99502 1.45448C1.60576 2.12561 1.48665 2.91978 1.66189 3.67558C1.83713 4.43138 2.29358 5.0921 2.93846 5.52346C2.45432 5.50809 1.98079 5.37774 1.55698 5.14318V5.18092C1.55655 5.88522 1.80003 6.56794 2.24605 7.11302C2.69206 7.6581 3.31308 8.03191 4.00353 8.17089C3.55505 8.2936 3.08435 8.31147 2.62785 8.22314C2.82269 8.82885 3.20176 9.35861 3.71217 9.73851C4.22258 10.1184 4.83886 10.3295 5.47501 10.3422C4.39503 11.1906 3.06094 11.6507 1.68761 11.6485C1.44406 11.6482 1.20075 11.6332 0.958984 11.6038C2.35414 12.4989 3.97708 12.9743 5.63466 12.9734Z"
        fill="white"
      />
    </svg>
  );
};
export const IconLinkedIn = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2294 0.504639H1.56409C0.957385 0.504639 0.466797 0.983615 0.466797 1.5758V14.2934C0.466797 14.8855 0.957385 15.3674 1.56409 15.3674H14.2294C14.8361 15.3674 15.3296 14.8855 15.3296 14.2963V1.5758C15.3296 0.983615 14.8361 0.504639 14.2294 0.504639ZM4.87628 13.1699H2.67009V6.07528H4.87628V13.1699ZM3.77319 5.10862C3.06488 5.10862 2.49301 4.53675 2.49301 3.83135C2.49301 3.12595 3.06488 2.55408 3.77319 2.55408C4.47859 2.55408 5.05046 3.12595 5.05046 3.83135C5.05046 4.53385 4.47859 5.10862 3.77319 5.10862ZM13.1321 13.1699H10.9288V9.7213C10.9288 8.89979 10.9143 7.84023 9.78216 7.84023C8.63552 7.84023 8.46135 8.73723 8.46135 9.66325V13.1699H6.26096V6.07528H8.37426V7.04484H8.40329C8.69648 6.48749 9.4164 5.8982 10.4876 5.8982C12.7199 5.8982 13.1321 7.36706 13.1321 9.27716V13.1699Z"
        fill="white"
      />
    </svg>
  );
};
