import Navbar from './navbar'
import Footer from './footer'
import { Outlet, useLocation } from 'react-router-dom'
import { useEffect } from 'react';

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    
    setTimeout(()=> {
      window.scrollTo(0, 0);
    }, 200) // Reset scroll position to top on component mount or location change
    // console.log(pathname);
  }, [pathname]);
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  )
}

export default Layout


// const ScrollToTop = () => {
  

//   return null; // This component doesn't render anything
// };