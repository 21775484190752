export const SIconTick = ({ className }) => {
  return (
    <svg
      //   width="8"
      //   height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current  ${className}`}
    >
      <path
        d="M0.724609 4.52876L2.41425 6.25991L7.50015 1.04883"
        strokeLinecap="round"
      />
    </svg>
  );
};
