import { Link } from "react-router-dom";

/**
 *
 * @param {object} params
 * @param {string} params.heading
 * @param {string} params.introText
 * @param {Array<{title: string, paragraphs: Array}>} params.contents
 * @param {string} params.className
 * @returns
 */
const HeadingWithContent = ({ heading, introText, contents, className }) => {
  return (
    <div className={`${className}`}>
      <h2 className=" text-sgrey-900 text-xl font-myriadProBold">{heading}</h2>
      {introText && <p className="text-sgrey-500 pt-2">{introText}</p>}
      {contents === undefined
        ? ""
        : contents.map((content) => (
            <div
              key={`${content.title}-${
                content.paragraphs && content.paragraphs[0]
              }`}
            >
              {content.title && (
                <p className="font-myriadProSemibold pt-4 pb-2">
                  {content.title}
                </p>
              )}
              <>
                {content.paragraphs &&
                  content.paragraphs.map((paragraph) => {
                    var splitWord = paragraph.split("@spLink@");
                    const isNotFirst =
                      content.paragraphs.indexOf(paragraph) !== 0;
                    return (
                      <span
                        key={paragraph}
                        className={`${isNotFirst && "pt-2 block"}`}
                      >
                        {splitWord.map((words) => {
                          // const me = 'mmee'

                          if (words.includes("?params=")) {
                            const stringParam = words.substring(
                              words.indexOf("{"),
                              words.lastIndexOf("}") + 1
                            );
                            // // console.log(stringParam, "String Params");
                            const params = JSON.parse(stringParam);
                            // // console.log(params, "Params");
                            if (words.includes("?type=external")) {
                              return (
                                <a
                                  href={params.to}
                                  className="text-sprimary underline"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {words.replace(
                                    `?type=external?params=${stringParam}`,
                                    ""
                                  )}
                                </a>
                              );
                            }
                            return (
                              <Link
                                key={words}
                                {...params}
                                className="text-sprimary underline"
                              >
                                {words.replace(`?params=${stringParam}`, "")}
                              </Link>
                            );
                          } else {
                            return <span>{words}</span>;
                          }
                        })}
                      </span>
                    );
                  })}
              </>
            </div>
          ))}
    </div>
  );
};

export default HeadingWithContent;
