import React from "react";
import privacy_asset from "assets/images/privacy_asset.png";
import privacy_asset_mobile from "assets/images/privacy_asset_mobile.png";
import { Link } from "react-router-dom";
import RoutePath from "router/route_path";
import { privacyPolicies } from "utils/data/privacy_policy/privacy_policies";
import useMediaQuery from "hooks/useMediaQuery";
import HeadingWithContent from "./components/heading_wth_content";
const PrivacyPolicy = () => {
  const mediaQuery = useMediaQuery();
  const isLargeScreen = mediaQuery.width >= 786;
  return (
    <>
      <section className="h-[80vh] max-h-[550px] bg-gradient pt-40 lg:pt-48">
        <div className="px-4 text-white relative md:px-16">
          <div className="md:w-[85%] mx-auto ">
            <p className="text-sm font-myriadProSemibold">
              Current as of 1 May 2023
            </p>
            <h1 className="font-myriadProBold text-4xl pt-3 md:text-6xl">
              Privacy Policy
            </h1>
            <p className="leading-[19px] pt-2 md:max-w-[55%]">
              By accessing our website, you are agreeing to be bound by these
              terms of service, all applicable laws and regulations, and agree
              that you are responsible for compliance with any applicable local
              laws.
            </p>
          </div>
          <div className="w-full overflow-clip mt-16 grid place-items-center  h-[300px] mx-auto  md:relative ">
            <img
              src={isLargeScreen ? privacy_asset : privacy_asset_mobile}
              alt="privacy-asset"
              className="h-full w-full object-cover  md:w-full"
            />
          </div>
        </div>
      </section>
      <section className="px-6 pt-[230px] pb-[100px] text-sgrey-500 md:pt-[260px] max-w-[900px] mx-auto">
        <p>
          Skitpay Inc, (the “Company”) is committed to maintaining robust
          privacy protections for its users.  Our Privacy Policy (“Privacy
          Policy”) is designed to help you understand how we collect, use and
          safeguard the information you provide to us and to assist you in
          making informed decisions when using our Service.
          <span className="block  pt-3">
            For purposes of this Agreement, “Site” refers to the company’s
            website, which can be accessed at{" "}
            <Link
              to={RoutePath.home}
              target="_blank"
              className="text-sprimary underline"
            >
              www.skitpay.com
            </Link>
            .
          </span>{" "}
          <span className="block pt-6">
            “Service” refers to the company’s services accessed via the Site, in
            which users can send money to anyone in our{" "}
            <Link
              to={RoutePath.home}
              target="_blank"
              className="text-sprimary underline"
            >
              supported countries{" "}
            </Link>{" "}
            for 0.99% per transaction.
          </span>
          <span className="block pt-6">
            The terms “we,” “us,” and “our”refer to the Company.
          </span>{" "}
          <span className="block pt-6">
            “You” refers to you, as a user of our Site or our Service.
          </span>{" "}
          <span className="block pt-6">
            By accessing our Site or our Service, you accept our Privacy Policy
            and Terms of Use (found here:{" "}
            <Link
              to={RoutePath.terms}
              target="_blank"
              className="text-sprimary underline"
            >
              www.skitpay.com/terms{" "}
            </Link>
            ), and you consent to our collection, storage, use and disclosure of
            your Personal Information as described in this Privacy Policy. 
          </span>
        </p>
        {privacyPolicies.map((policy) => {
          return (
            <HeadingWithContent
              key={policy.heading}
              className="pt-8"
              heading={policy.heading}
              introText={policy.introText}
              contents={policy.contents}
            />
          );
        })}
      </section>
    </>
  );
};

export default PrivacyPolicy;
