import RoutePath from "router/route_path";

export const navbarLinks = [
  {
    name: "Home",
    to: "/",
  },
  // {
  //     name: 'About Us',
  //     to: '/',
  // },
  // {
  //     name: 'How it works',
  //     to: '/',
  // },
  {
    name: "Contact",
    to: RoutePath.contact,
  },
  {
    name: "Join our waitlist",
    to: "/",
  },
];
