import HeaderText from "components/header_text";
import React from "react";
import { productMetas } from "utils/data/home";



const ProductMeta = () => {
  const metas = productMetas.map((meta) => (
    <MetaItem
      key={meta.title}
      meta={meta}
    />
  ));
  return (
    <div className=" py-16 px-4 flex flex-col items-center text-center lg:px-[70px] lg:space-y-9">
      <div className="layout-container space-y-16">{metas}</div>
      
    </div>
  );
};

export default ProductMeta;

const MetaItem = ({ meta}) => {
  const isReversed  = productMetas.indexOf(meta)%2===1
  return (
   
      <div className={`w-full flex flex-col justify-center items-center ${isReversed?'lg:flex-row-reverse':'lg:flex-row'} lg:gap-x-20`}>
      {/* <div className="px-3 flex flex-col items-center max-w-[365px] lg:text-start">
        <h2 className="stext-heading">{meta.title}</h2>
        <p className="pt-6 stext-base">{meta.description}</p>
      </div> */}
      <HeaderText title={meta.title} desc={meta.description} className='md:max-w-[365px] lg:text-start'/>

      <div className="w-full mt-10 rounded-2xl ring-8 ring-[#F4EBFF] max-w-[430px] max-h-[300px] lg:max-w-[550px] lg:max-h-[350px] overflow-hidden flex items-center">
        <img src={meta.image} alt="img-preview" className="w-full" />
      </div>        
    </div>
    
  );
};
