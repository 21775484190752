import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

// images
import bgShapes from "assets/waitlist/bg_shapes.png";
import error_img from "assets/waitlist/error_img.png";
import success_anim from "assets/waitlist/success_anim.gif";
import { Button } from "components/input";

const WaitListStatus = ({
  isOpen,
  handleClose,
  isError,
  errorMessage,
  successMessage,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[999999]"
        onClose={handleClose}
      >
        <div className="min-h-screen text-center">
          <Dialog.Overlay className="fixed left-0 top-0 h-full w-full bg-black bg-opacity-75 z-[999999]" />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="inline-block w-11/12 text-left align-middle transition-all z-[999999999] relative shadow-xl bg-white max-w-lg px-6 pt-[41px] pb-6 rounded-[10px]">
            {/* <Dialog.Title
							as="div"
							className="flex justify-between items-center"
						>
							<img
								src={closeIcon}
								alt=""
								className="cursor-pointer mr-auto"
								onClick={handleClose}
							/>
						</Dialog.Title> */}
            <section className="my-6">
              <div className="mx-auto w-fit flex flex-col items-center">
                <div className="relative  h-[200px] md:h-[300px]">
                  <img src={bgShapes} alt="bg-shapes" />
                  <img
                    src={isError ? error_img : success_anim}
                    alt="bg-shapes"
                    className="absolute h-full top-0 -translate-x-1/2 left-[50%] -z-10"
                  />
                </div>
              </div>
              <div className="text-2xl leading-[29px] text-black text-center myriad-bold md:text-[32px] lg:leading-[38px] mb-2 md:mb-6">
                {!isError ? "Great!" : "Sorry"}
              </div>
              <p className="text-black myriad-regular  text-center text-base mb-7 md:text-[18px] leading-6 md:leading-7 max-w-[350px] mx-auto">
                {!isError
                  ? successMessage ??
                    "We'll notify you when we have new updates."
                  : errorMessage ??
                    "An error occured. Kindly check your network connection and/or your form input."}
              </p>
              <Button
                title="OKAY"
                className="border border-[#670DBE] md:mt-0 shadow-[0_1px_2px_rgba(16,24,50,0.05)] btn-bg w-[202px] mx-auto flex items-center focus:outline-none justify-center myriad-bold"
                onClick={handleClose}
              />
              {/* <div className="my-10">
								<WaitListForm {...{ handleClose }} />
							</div> */}
            </section>
            <div>
              <p className="text-center text-base text-sgrey-100 myriad-regular">
                © 2022 Skitpay. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default WaitListStatus;
