import Spin from "components/loader/spin";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const Button = ({ title, href, className, isLoading, ...props }) => {
	return href ? (
		<Link
			to={href}
			className={twMerge(
				`rounded-[7px] w-[102px] md:w-[200px] h-[44px] text-white myriad-regular text-base leading-6 ${className}`
			)}
		>
			{title}
		</Link>
	) : (
		<button
			className={twMerge(
				`rounded-[7px] w-[102px] md:w-[200px] h-[44px] text-white myriad-regular text-base leading-6  ${className}`
			)}
			{...props}
		>
			{isLoading ? <Spin /> : title}
		</button>
	);
};

export default Button;
