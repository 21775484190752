import fabari from "assets/team/fabari.jpeg";
import andrea from "assets/team/andrea.jpg";
import godson from "assets/team/godson.jpg";
import jude from "assets/team/jude.jpg";
import neha from "assets/team/neha.jpg";
import ndubisi from "assets/team/ndubisi.png";
import precious from "assets/team/precious.jpg";
export const coreTeam = [
  {
    name: "Fabari Agbora",
    role: "Co-founder & CEO",
    linkedin: "https://www.linkedin.com/in/fabariagbora/",
    twitter: "",
    image: fabari,
  },
  {
    name: "Victory Godson ",
    role: "Co-founder & COO",
    linkedin: "https://www.linkedin.com/in/godsonchuks/",
    twitter: "",
    image: godson,
  },
  {
    name: "Precious Utobivbi",
    role: "Co-founder & CPO",
    linkedin: " https://www.linkedin.com/in/precious-o-utobivbi/",
    twitter: "",
    image: precious,
  },
  // {
  //   name: "Jude Okorharen",
  //   role: "CBDO",
  //   linkedin: "https://www.linkedin.com/in/jude-okorharen/",
  //   twitter: "",
  //   image: jude,
  // },
  {
    name: "Andrea Frosinini",
    role: "Advisor",
    linkedin: "https://www.linkedin.com/in/andreafrosinini/",
    twitter: "",
    image: andrea,
  },
  {
    name: "Ndubuisi Ekekwe",
    role: "Advisor",
    linkedin: "https://www.linkedin.com/in/ndubuisi-ekekwe-36068210/",
    twitter: "",
    image: ndubisi,
  },
  {
    name: "Neha Jain",
    role: "Advisor",
    linkedin: "https://www.linkedin.com/in/neha-jain-a7b33ba2/",
    twitter: "",
    image: neha,
  },
  
];
