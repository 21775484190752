import RoutePath from "router/route_path";

export const footerLinks = [
  {
    category: "Company",
    links: [
    //   {
    //     name: "About us",
    //     to: "/",
    //   },
      {
        name: "Contact",
        to: RoutePath.contact,
      },
    ],
  },
  {
    category: "Legal",
    links: [
      {
        name: "Terms",
        to: RoutePath.terms,
      },
      {
        name: "Privacy",
        to: RoutePath.privacyPolicy,
      },
    ],
  },
  {
    category: "Social",
    links: [
      {
        name: "Twitter",
        to: "https://twitter.com/Skitpay",
      },
      {
        name: "LinkedIn",
        to: "https://www.linkedin.com/company/skitpay/",
      },
      {
        name: "Instagram",
        to: "https://www.instagram.com/skitpay/",
      },
    ],
  },
];
