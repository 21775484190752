import { useRef, useState } from "react";
import { navbarLinks } from "utils/data";
import { Link } from "react-router-dom";
import { Button } from "components/input";
import { WaitList, WaitListStatus } from "components/modal";

// images
import logo from "assets/logo.png";
import menu from "assets/icons/i_menu.png";
import close from "assets/icons/close.svg";
import RoutePath from "router/route_path";

const Navbar = () => {
  const [isClosed, setIsClosed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const dropDownLinksElement = useRef();

  const handleCloseModal = () => setIsModalOpen(false);
  const handleCloseStatusModal = () => setIsStatusModalOpen(false);
  const handleOpenStatusModal = () => {
    setTimeout(() => {
      setIsStatusModalOpen(true);
    }, 200);
  };
  const handleOpenModal = () => setIsModalOpen(true);
  const handleToggle = () => {
    if (!isClosed) {
      window.scrollTo(0, 0);
    }
    setIsClosed(!isClosed);
  };

  return (
    <>
      <WaitList
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        {...{ setIsError, handleOpenStatusModal }}
      />
      <WaitListStatus
        isOpen={isStatusModalOpen}
        handleClose={handleCloseStatusModal}
        {...{ isError }}
      />
      <div className="bg-white z-[1000] fixed w-full">
        <nav className="flex justify-between h-14 lg:h-[95px] items-center px-5 lg:px-[104px]">
          <Link to={RoutePath.home}><img src={logo} alt="logo" className="h-8" /></Link>
          <ul className="hidden lg:flex items-center space-x-[35px]">
            {navbarLinks.map((link, index) => (
              <li key={index}>
                {link.name === "Join our waitlist" ? (
                  <Button
                    onClick={handleOpenModal}
                    title={link.name}
                    className="border border-[#670DBE] mt-[14px] md:mt-0 shadow-[0_1px_2px_rgba(16,24,50,0.05)] btn-bg w-[144px] flex items-center justify-center"
                  />
                ) : (
                  <Link
                    to={link.to}
                    className="myriad-semibold text-black text-sm leading-[17px]"
                  >
                    {link.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <img
            src={isClosed ? close : menu}
            alt="logo"
            className="h-5 lg:hidden"
            onClick={handleToggle}
          />
        </nav>
      </div>
      <ul
        ref={dropDownLinksElement}
        className={`lg:hidden transition-all ease-in-out duration-[700ms] overflow-hidden ${
          isClosed ? "opacity-100 " : "opacity-0"
        }`}
        style={
          isClosed
            ? {
                height: dropDownLinksElement.current?.scrollHeight,
              }
            : {
                height: 0,
              }
        }
      >
        <div className="pt-14 pb-16 z-[1000] flex flex-col space-y-10 items-center">
          {navbarLinks.map((link, index) => (
            <li key={index}>
              {link.name === "Join our waitlist" ? (
                <Button
                  onClick={handleOpenModal}
                  title={link.name}
                  className="border border-[#670DBE] mt-[14px] md:mt-0 shadow-[0_1px_2px_rgba(16,24,50,0.05)] btn-bg w-[342px] flex items-center justify-center"
                />
              ) : (
                <Link
                  to={link.to}
                  className="myriad-semibold text-black text-sm leading-[17px]"
                >
                  {link.name}
                </Link>
              )}
            </li>
          ))}
        </div>
      </ul>
    </>
  );
};

export default Navbar;
