import img1 from "assets/images/img_1.png";
import img2 from "assets/images/img_2.png";

export const productMetas = [
    {
      title: "Make transactions in your local currency",
      description:
        "With Skitpay, you can make payments in your local currency from anywhere to anywhere in the world, making receiving of payment easy for everyone",
      image: img1,
    },
    {
      title: "Manage all your Accounts in One Place",
      description:
        "Designed to be easy for all. Connect your local and international bank accounts for easy transactions. Track your spending from any where in the world.",
      image: img2,
    },
  ];