import React from "react";
import { Button, TextInput } from "../input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().required().email(),
});

const SubscribeForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // // console.log(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="md:flex items-center md:space-x-[43px] md:w-[490px]"
    >
      <TextInput
        name="email"
        id="email"
        placeholder="Enter your email"
        // className="mt-0"
        {...{ register, errors }}
      />
      <Button
        title="Subscibe"
        className="border border-[#670DBE] mt-[14px] md:mt-0 shadow-[0_1px_2px_rgba(16,24,50,0.05)] btn-bg"
      />
    </form>
  );
};

export default SubscribeForm;
