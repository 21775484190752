//  The name property and validator property is required for form validation

import alertCircle from "assets/icons/alert-circle.svg";

/**
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string=} props.title
 * @param {import('./form_state').formprops=} props.formprops
 * @param {string=} props.className
 * @param {'input' | 'textarea'=} props.inputType
 * @param {string=} props.titleStyle
 * @param {string=} props.inputStyle
 * @param {object=} props.props
 * @param {bool=} props.disabled
 * @param {string=} props.value
 * @param {boolean=} props.readOnly
 * @param {string=} props.placeholder
 * @param {()=>any=} props.onFocus
 * @param {()=>any=} props.onBlur
 * @param {(value:string)=>any=} props.validator
 * @param {React.MutableRefObject<HTMLInputElement>=} props.ref
 * @param {any=} props.trailing
 * @param {bool=} props.reverseTrailing
 * @param {()=>any=} props.onChanged
 * @param {boolean=} props.autoComplete
 * @param {string=} props.type
 * @returns
 */
const CustomInput = ({
  name,
  title,
  rows,
  validator,
  formprops,
  className,
  titleStyle,
  inputStyle,
  inputType = "input",
  disabled = false,
  readOnly = false,
  value,
  placeholder,
  autoComplete = true,
  onBlur,
  onFocus,
  type = "text",
  trailing,
  reverseTrailing = false,
  props,
  onChanged = () => {},
}) => {
  // console.log(formprops.values, "Values");
  // console.log(formprops.errors, "Errors");
  const input =
    inputType === "input" ? (
      <input
        {...props}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        value={value !== undefined ? value : formprops.values[name] ?? ""}
        disabled={disabled}
        name={name}
        type={type}
        className={`w-full h-[40px]  outline-none stext-base ${inputStyle}`}
        autoComplete={autoComplete ? "on" : "off"}
        onChange={(event) => {
          if (onChanged !== null || onChanged !== undefined) {
            onChanged(event);
          }
          value = event.target.value;
          // // console.log(formprops);
          if (formprops !== undefined) {
            formprops.handleChange(event);
          }
        }}
      />
    ) : (
      <textarea
        {...props}
        rows={rows}
        disabled={disabled}
        name={name}
        value={value !== undefined ? value : formprops.values[name] ?? ""}
        className={`w-full outline-none stext-base py-2 ${inputStyle}`}
        onChange={(event) => {
          if (onChanged !== null) {
            onChanged(event);
          }
          value = event.target.value;
          if (formprops !== undefined) {
            formprops.handleChange(event);
          }
        }}
      ></textarea>
    );
  return (
    <div className={`flex flex-col items-start w-full`}>
      {title && (
        <p
          className={`text-sSm md:text-[14px] font-semibold text-sgrey-1 ${titleStyle}`}
        >
          {title}
        </p>
      )}
      <div
        className={`w-full focus-within:ring border-sgrey-4 border-opacity-75 focus-within:ring-gray-100  transition-all flex items-center px-3 space-x-3 border rounded-lg  ${className} ${
          formprops !== undefined
            ? formprops.errors[name] != null
              ? "border-error-300 focus-within:ring-red-100"
              : ""
            : ""
        }`}
      >
        <div
          className={`flex items-center w-full ${
            reverseTrailing && "flex-row-reverse"
          }`}
        >
          {input}
          <div className="w-[20px]"></div>
          <div className="flex-shrink-0">{trailing}</div>
        </div>
        {formprops && formprops.errors[name] && (
          <img src={alertCircle} alt="alert" />
        )}
      </div>

      {formprops !== undefined
        ? formprops.errors[name] && (
            <p className="text-red-400 text-xs font-normal slide_in">
              {formprops.errors[name]}
            </p>
          )
        : null}
    </div>
  );
};

export default CustomInput;
