import React from 'react'
import { twMerge } from 'tailwind-merge'

const HeaderText = ({title, desc, titleClass, descClass, className}) => {
  return (
    <div className={twMerge(`max-w-[300px] md:max-w-[385px] mx-auto ${className}`)}>
        <h2 className={twMerge(`stext-heading ${titleClass}`)}>{title}</h2>
        <p className={twMerge(`pt-6 stext-base ${descClass}`)}>
          {desc}
        </p>
      </div>
  )
}

export default HeaderText