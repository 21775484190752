class FormVaidation {
  // static #balance = useLoginController().user.balance;

  static validateSingleName = (value) => {
    if (value.includes(" ")) {
      return `Only a single name is required`;
    } else if (value.length <= 2) {
      return `The name is too short`;
    }
    return null;
  };
  static validateMessage = (value) => {
    if (value.length <= 10) {
      return `The message is too short`;
    }
    return null;
  };

  static validateEmail = (value) => {
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.length <= 0) {
      return "Email Field cannot be empty";
    } else if (!regexEmail.test(value)) {
      return "Enter a valid email address";
    }
    return null;
  };
  static validatePassword = (value) => {
    if (!/(?=.*?[A-Z])/.test(value)) {
      return "Password must have at least one upper case English letter";
    } else if (!/(?=.*?[a-z])/.test(value)) {
      return "Password must have at least one lower case English letter";
    } else if (!/(?=.*?[0-9])/.test(value)) {
      return "Password must have At least one digit";
    } else if (!/(?=.*?[#?!@$%^&*-])/.test(value)) {
      return "Password must have at least one special character";
    } else if (value.length < 8) {
      return "Password must have at 8 characters";
    }

    return null;
  };

  static validatePhone = (value) => {
    if (!/^[\d+]+$/.test(value)) {
      return "Enter a valid phone";
    } else if (value.length < 6) {
      return "Phone number too short";
    }
    return null;
  };

  /** @param {string} value*/
  static validateAccountNumber = (value) => {
    if (value.length <= 5) {
      return "Account Number is too short";
    }
    return null;
  };
  /** @param {string} value*/
  static validateAmount = (value) => {
    if (value.length < 1) {
      return "Enter an amount to send";
    }
    // if (typeof value !== "number") {
    //   return "Only numbers are allowed";
    // }
    if (parseInt(value) < 10) {
      return "cannot send amount less than 10";
    }

    return null;
  };
}

export default FormVaidation;
