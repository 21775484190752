import React from "react";
import transact from "assets/icons/i_trans.svg";
import secure from "assets/icons/i_secure.svg";
import wallet from "assets/icons/i_wallet.svg";
import rates from "assets/icons/i_rates.svg";
import HeaderText from "components/header_text";

const productServices = [
  {
    title: "Low transaction cost",
    description: "Send money internationally at a very convenient fee of 0.99%",
    icon: transact,
  },
  {
    title: "Secure transactions",
    description: "We ensure to protect your financial data and personal information.",
    icon: secure,
  },
  {
    title: "Instant payment",
    description: "Send and receive money to any part of the world under ten (10) minutes",
    icon: wallet,
  },
  {
    title: "Cheaper rates",
    description: "Make international payments at the lowest and most affordable rates",
    icon: rates,
  },
];

const AboutProduct = () => {
  const services = productServices.map((service) => (
    <ProductService
      key={service.title}
      icon={service.icon}
      title={service.title}
      description={service.description}
    />
  ));
  return (
    <section className="py-16 px-2 flex flex-col  bg-sgrey-50 text-center">
      <div className="layout-container">
      {/* <h2 className="stext-heading">
      The future of <span className="text-sprimary">secure</span>{' '}and{' '} 
      <span className="text-sprimary">seamless</span>{' '}
        transactions
      </h2>
      <p className="pt-6 stext-base text-sgrey-500">
      Say goodbye to expensive, slow, and delayed international payments.
      </p> */}
      <HeaderText title='The future of secure and seamless transactions' desc='Say goodbye to expensive, slow, and delayed international payments.' className='md:max-w-[420px]'/>
      <div className="pt-12  gap-4 grid max-w-[470px] mx-auto md:grid-cols-2 md:max-w-[900px] items-center justify-center">{services}</div>
      </div>
    </section>
  );
};

export default AboutProduct;

const ProductService = ({ title, description, icon }) => {
  return (
    <div className="bg-white rounded-xl px-11 py-8 flex flex-col items-start text-left">
      <div className="md:max-w-[250px]">
      <img src={icon} alt="transact" className="w-[50px]" />
      <h2 className="stext-heading text-[1.2rem] pt-5">{title}</h2>
      <p className="stext-base pt-3">{description}</p>
      </div>
    </div>
  );
};
