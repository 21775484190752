import {
  AboutProduct,
  Companies,
  // CurrecncyCalculator,
  Hero,
  Last,
  ProductMeta,
} from "components/section/home";
import MeetTheTeam from "components/section/home/meet_team";

const Home = () => {
  return (
    <div>
      <Hero />
      <Companies />
      <AboutProduct />
      {/* <CurrecncyCalculator /> */}
      <ProductMeta />
      <Last />
      <MeetTheTeam />
    </div>
  );
};

export default Home;
