// @ts-check

import React, { useEffect } from "react";

/**
 *
 * @param {object} props
 * @param {Array<JSX.Element>=} props.children
 * @param {import('./form_state').formprops} props.formStateProps - Can be gotton from the useFormState hook, eg. const formState = useFormState()
 * @param {string=} props.className
 */
const CustomForm = ({ children, formStateProps, className }) => {
  let formprops = null;
  try {
    formprops = {
      values: formStateProps.values,
      errors: formStateProps.errors,
      handleChange: formStateProps.handleChange,
      validate: formStateProps.validate,
      isValid: formStateProps.isValid,
    };
  } catch (error) {
    console.error("Form props property must be provided on the form Component");
  }

  useEffect(() => {
    if (formStateProps != null) {
      formStateProps.addValidators(validators);
      formStateProps.addInitialErrors(initialErrors, validators);
    }
  }, []);

  let initialErrors = [];
  let validators = {};
  const processChildren = (chld) => {
    return React.Children.map(chld, (child) => {
      // Checking isValidElement is the safe way and avoids a
      // typescript error too.
      if (React.isValidElement(child)) {
        const subChildren = React.Children.toArray(child.props.children);
        if (subChildren.length > 0) {
         
          processChildren(child.props.children);
        } else if (child.props.name != null && child.props.validator != null) {
          initialErrors.push(child.props.name);
          validators[child.props.name] = child.props.validator;
        }
        return React.cloneElement(child, { formprops });
      }
      return child;
    });
  };
  const childrenWithProps = processChildren(children);

  return <div className={`${className}`}>{childrenWithProps}</div>;
};

export default CustomForm;
