import { footerLinks } from "utils/data"
import { SubscribeForm } from "components/form"
import {Link} from 'react-router-dom'
// images
import logo from 'assets/footer/skitpay-logo.svg'

const Footer = () => {
    return (
        <footer className='bg-sprimary-200 pt-[56px] pb-12 md:pb-[58px] text-white'>
            <div className="layout-container">

                {/* wait list */}
                <div className="border-b border-sgrey-600 pb-[55px] md:pb-[59px] xl:flex justify-between">
                    <div>
                        <h2 className='myriad-bold leading-6 text-white text-xl'>Join our waitlist</h2>
                        <p className='myriad-regular text-sm leading-[21px] text-sgrey-200 mt-4 mb-7 lg:mb-0 md:w-[387px]'>Pay now with Skitpay. Be a part of the revolutionary change in cross-border payments. Join our waitlist to receive latest updates </p>
                    </div>
                    <SubscribeForm />
                </div>

                <div className="lg:grid grid-cols-[1fr_2fr] lg:mt-[56px] lg:border-b border-sgrey-600 lg:pb-[56px]">
                    {/* footer links */}
                    <div className="mt-[56px] lg:mt-0 space-y-8 md:space-y-0 md:grid grid-cols-3">
                        {
                            footerLinks.map((item, index) => (

                                <ul key={`category-${index}`}>
                                    <li className="text-xs leading-[17px] myriad-bold mb-[14px] text-sgrey-400">{item.category}</li>
                                    <div className="space-y-[10px]">
                                        {
                                            item.links.map((link, index) => (
                                                <li key={index}>
                                                    {item.category.toLocaleLowerCase()==='social'?<a href={link.to} target="_blank"  rel="noreferrer" className="text-sgrey-200 myriad-semibold text-sm leading-[17px]">{link.name}</a>:<Link to={link.to}  className="text-sgrey-200 myriad-semibold text-sm leading-[17px]">{link.name}</Link>}
                                                </li>
                                            ))
                                        }
                                    </div>
                                </ul>
                            ))
                        }
                    </div>

                    {/* Logo */}
                    <div className="mt-7 lg:mt-0 pb-[55px] lg:pb-0 border-b lg:border-0 border-sgrey-600 lg:order-first">
                        <img src={logo} alt='skitpay logo' />
                    </div>
                </div>


                <p className="text-center myriad-regular text-sgrey-400 text-sm leading-[17px] mt-7">© 2022 Skitpay. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer