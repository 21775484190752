import React from "react";
import { lastData } from "utils/data";

const Last = () => {
  return (
    <section className="bg-[#F6F6F6] pt-20 md:pt-24 pb-[125px] md:pb-[104px]">
      <div className="layout-container">
        <div className="text-center mb-12">
          <h1 className="myriad-bold text-2xl md:text-[35px] leading-[29px] md:leading-[42px] text-sgrey-900 mb-[17px] md:w-[496px] md:mx-auto">
            Advancing currency independence globally
          </h1>
          <p className="myriad-regular text-sm leading-[21px] text-sgrey-500 w-[289px] md:w-full mx-auto">
            The new way to pay with Skitpay. A glimpse of how the application
            works!
          </p>
        </div>

        <div className="space-y-8 lg:space-y-0 lg:grid grid-cols-3 gap-x-[76px] 2xl:w-4/5 2xl:mx-auto">
          {lastData.map((item, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <img src={item.image} alt="" className="mb-8 w-[196px] h-[156px]"/>
              <h3 className="text-black myriad-bold text-xl leading-6 mb-4">
                {item.heading}
              </h3>
              <p className="text-base leading-6 myriad-regular text-[#616161] w-[285px] lg:w-full xl:w-[300px] mx-auto">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Last;
