import { useEffect, useState } from "react";
import AppRouter from "router";

import preview from "assets/images/preview3x.png";
import union from "assets/utils/union.svg";
import decLeft from "assets/icons/i_dec_left.svg";
import decRight from "assets/icons/i_dec_right.svg";

import privacy_asset from "assets/images/privacy_asset.png";
import privacy_asset_mobile from "assets/images/privacy_asset_mobile.png";

const imagesPromiseList = [
	preview,
	union,
	decLeft,
	decRight,
	privacy_asset,
	privacy_asset_mobile,
  ];

function App() {
  const [isPreloading, setisPreloading] = useState(false);
  useEffect(() => {
    preloadAllImages();
  }, []);

  const preloadSrcList = [];

  const preloadAllImages = async () => {
    
    setisPreloading(true);
    for (const i of preloadSrcList) {
      imagesPromiseList.push(preloadImage(i));
    }

    await Promise.all(imagesPromiseList);
    setisPreloading(false);
  };
  return isPreloading ? <div></div> : <AppRouter />;
}

export default App;

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}
