import axios from "axios";

const instance = axios.create({
  // baseURL: "https://skitpay.onrender.com/api/v1/",
  baseURL: "https://skit-waitlist-test.vercel.app/api/v1/",
  // timeout: 6000,
  headers: { "Content-Type": "application/json" },
});

export const joinWaitingList = async ({ name, email, country }) => {
  const body = { name, email, country };
  let response;
  try {
    response = await instance.post("waitlist", body);
    // // console.log(response, "The Response");
    return response;
  } catch (error) {
    // // console.log(error.response, "The Response");
    return error.response;
  }
};

export const sendContactMessage = async ({
  firstName,
  lastName,
  email,
  phone,
  message,
}) => {
  const body = { firstName, lastName, email, phone, message };
  // console.log(body);
  let response;
  try {
    response = await instance.post("contact-messages", body);
    // console.log(response);
    return response;
  } catch (error) {
    // console.log(error);
    return error.response;
  }
};
