import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Grid, Autoplay } from "swiper";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";

import {
  IconLinkedIn,
  IconTwitter,
  IconsArrorUpperRight,
} from "assets/icons/app_icons";
import { coreTeam } from "utils/data/core_team";
import useMediaQuery from "hooks/useMediaQuery";

const MeetTheTeam = () => {
  const [progress, setprogress] = useState(0);
  const mq = useMediaQuery();
  const isMobile = mq.width <= 650;
  const istab = mq.width <= 1080;
  return (
    <section className="bg-white pt-20 md:pt-24 pb-[125px] md:pb-[104px]">
      <div className="layout-container">
        <div className="text-center mb-12">
          <h1 className="myriad-bold text-2xl md:text-[35px] leading-[29px] md:leading-[42px] text-sgrey-900 mb-[17px] md:w-[496px] md:mx-auto">
            Our core team
          </h1>
          <p className="myriad-regular text-sm leading-[21px] text-sgrey-500 w-[289px] md:w-full mx-auto max-w-[650px]">
            Our philosophy is simple — hire a team of diverse, passionate people
            and foster a culture that empowers you to do you best work.
          </p>
        </div>

        <Swiper
          spaceBetween={10}
          slidesPerView={isMobile ? 1 : istab ? 2 : 3}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Pagination, Grid, Autoplay]}
          navigation={isMobile ? false : true}
          // pagination={{ clickable: true }}
          onSlideChange={(swiper) => {
            // // console.log("slide change", swiper.progress);
            setprogress(swiper.progress);
          }}
          // onSwiper={(swiper) => // console.log(swiper)}
          // className=""
        >
          {isMobile
            ? groupIntoTwos(coreTeam).map((arrTeam) => (
                <SwiperSlide key={arrTeam[0].name}>
                  <div className="flex flex-col space-y-5">
                    <SliderItem team={arrTeam[0]} />
                    {arrTeam[1] !== undefined ? (
                      <SliderItem team={arrTeam[1]} />
                    ) : null}
                  </div>
                </SwiperSlide>
              ))
            : coreTeam.map((team) => {
                return (
                  <SwiperSlide key={team.name}>
                    <SliderItem team={team} />
                  </SwiperSlide>
                );
              })}
        </Swiper>
        <div className=" mt-4 md:mt-14 flex justify-center items-center">
          <div className="w-[250px] h-2 rounded-full bg-sgrey-200">
            <motion.div
              initial={{ width: 200 }}
              animate={{ width: 250 * progress }}
              className="w-[200px] h-2 rounded-full bg-sprimary"
            ></motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetTheTeam;
/**
 *
 * @param {object} params
 * @param {{name: string, role: "Founder & CEO",linkedin: "",twitter: "",image: fabari,}} params.team
 * @returns
 */
const SliderItem = ({ team }) => {
  return (
    <div className="grid place-items-center">
      <div className="w-64 h-72 md:w-72 md:h-80 bg-gray-50 rounded-2xl relative overflow-hidden">
        <div className="w-full h-full flex  py-3 md:py-6 justify-center items-end absolute z-[3]">
          <div className="h-[85px] w-[220px] md:h-[100px] md:w-[256px] bg-[rgba(255,255,255,0.3)] rounded-lg border-[1px] border-white border-opacity-50 backdrop-blur-md px-4 py-2 md:py-3 flex flex-col justify-between">
            <div className="flex justify-between items-center">
              <p className="text-white text-lg font-myriadProBold ">
                {team.name}
              </p>
              <IconsArrorUpperRight />
            </div>
            <p className="text-white text-xs font-myriadProSemibold">
              {team.role}
            </p>
            <div className="flex items-center space-x-4">
              {/* <a href={team.twitter} target="_blank" rel="noreferrer">
                <IconTwitter />
              </a> */}
              <a href={team.linkedin} target="_blank" rel="noreferrer">
                <IconLinkedIn />
              </a>
            </div>
          </div>
        </div>
        <div className="absolute w-full bg-black bg-opacity-5 h-full z-[2]"></div>
        <img
          src={team.image}
          className="absolute z-[1]  object-cover h-full w-full"
          alt={team.name}
        />
      </div>
    </div>
  );
};

function groupIntoTwos(arr) {
  const result = [];

  for (let i = 0; i < arr.length; i += 2) {
    if (i === arr.length - 1) {
      result.push([arr[i]]);
    } else {
      const pair = [arr[i], arr[i + 1]];
      result.push(pair);
    }
  }

  return result;
}
