// import xinfinLogo from "assets/partners-logo/Skitpay-Xinfin.png";
// import xdcLogo from "assets/partners-logo/Skitpay-XDC.png";
import dreamValleyLogo from "assets/partners-logo/Skitpay-Dream-Valley-Global-logo.png";
import farmsby from "assets/partners-logo/Farmsby.png";
import intelliBlock from "assets/partners-logo/Intelliblock Technologies logo.png";

// import layer from "assets/icons/layer.svg";
// import sisyphus from "assets/icons/sisyphus.svg";
// import circoo from "assets/icons/circoo.svg";
// import catalog from "assets/icons/catalog.svg";
// import quotient from "assets/icons/quotient.svg";

const Companies = () => {
  const partners = [
    // {
    // 	logo: xinfinLogo,
    // 	name: "Xinfin"
    // },
    // {
    // 	logo: xdcLogo,
    // 	name: "XDC"
    // },
    {
      logo: dreamValleyLogo,
      name: "Dream Valley Global",
    },
    {
      logo: farmsby,
      name: "Farmsby",
    },
    {
      logo: intelliBlock,
      name: "Intelliblock",
    },
  ];
  return (
    <section className="py-14 layout-container">
      <div className="bg-sgrey-50 flex flex-col justify-center items-center rounded-xl px-8 py-11">
        <h3 className="text-sgrey-500 stext-base">Trusted Partners</h3>
        <div className="pt-4 flex flex-wrap gap-6 items-center justify-center md:gap-x-11 lg:gap-y-0 ">
          {partners.map((partner) => (
            <div
              key={partner.name}
              className="flex items-center space-x-2 justify-center"
            >
              {/* <div className="h-3 w-4 bg-slate-500"></div> */}
              <img src={partner.logo} alt={partner.name} className="h-12 md:h-20" />
              {/* <p className="font-myriadProSemibold text-[1.1rem] md:text-[1.3rem] lg:text-[1.5rem]">
                {partner.name}
              </p> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Companies;
