import errorIcon from "assets/icons/error.svg";

const TextInput = ({
  type,
  placeholder,
  name,
  id,
  label,
  className,
  register,
  errors,
  validate,
  onChanged,
  ...props
}) => {
  return (
    <div className="w-full">
      {label ? (
        <label
          htmlFor={id}
          className="mb-[6px] block text-sm leading-[17px] text-sgrey-700 myriad-semibold"
        >
          {label}
        </label>
      ) : null}
      <div
        className={`flex items-center pr-[15px] border bg-white rounded-lg ${
          errors?.[name] ? "border-error-300" : "border-sgrey-300"
        }`}
      >
        {type === "textArea" ? (
          <textarea
            onChange={onChanged}
            {...{ placeholder, id, ...props }}
            {...register(name)}
            className={`w-full  py-3  rounded-lg focus:outline-none text-black px-2 stext-base ${className}`}
          />
        ) : (
          <input
            onChange={(e) => {
              // console.log(e.target.value);
            }}
            {...{ placeholder, id, ...props }}
            {...register(name)}
            className={`w-full h-10  rounded-lg focus:outline-none text-black px-2 stext-base ${className}`}
          />
        )}
        {errors?.[name] ? <img src={errorIcon} alt="" /> : null}
      </div>
      {errors?.[name]?.type === "required" ? (
        <p className="myriad-regular mt-[6px] text-sm leading-[17px] text-error-500">
          This field is required
        </p>
      ) : errors?.[name] ? (
        <p className="myriad-regular mt-[6px] text-sm leading-[17px] text-error-500">
          Kindly check your input
        </p>
      ) : null}
    </div>
  );
};

export default TextInput;
