import React from "react";
import CountryDropdown from "country-dropdown-with-flags-for-react";

const SelectCountry = ({ onChange }) => {
  // // console.log(field)
  return (
    <div>
      <label
        htmlFor="country"
        className="mb-[6px] block text-sm leading-[17px] text-sgrey-700 myriad-semibold"
      >
        Country
      </label>
      <div className="border border-sgrey-300 rounded-lg shadow-[0_1px_2px_rgba(16,24,40,0.05)] h-[44px] flex items-center">
        <CountryDropdown
          id="country"
          className="focus:outline-none"
          preferredCountries={["gb", "us"]}
          value="Nigeria"
          handleChange={onChange}
        />
      </div>
    </div>
  );
};

export default SelectCountry;
