import Layout from "components/layout";
import Home from "pages/Home";
import {
  createBrowserRouter,
  createRoutesFromElements,
  // Navigate,
  Route,
  RouterProvider,
  // useLocation,
} from "react-router-dom";
import RoutePath from "./route_path";
import PrivacyPolicy from "pages/privacy_policy/privacy_policy";
import TermsOfUse from "pages/terms_of_use/terms_of_use";
import { useEffect } from "react";
import ContactPage from "pages/contact_page/contact_page";

const CustomRedirect = ({ url }) => {
  useEffect(() => {
    window.location = url;
  }, [url]);
  return <>Redirecting...</>;
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path={RoutePath.home} element={<Layout />}>
      <Route index element={<Home />} />
      <Route path={RoutePath.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={RoutePath.terms} element={<TermsOfUse />} />
      <Route path={RoutePath.contact} element={<ContactPage />} />

      {/*
            <Route path="/create-nft" element={<CreateNft />} />
            <Route path="/stake" element={<Stake />} />
            <Route path="/create-pool" element={<CreatePool />} /> 
            */}
    </Route>,
    <Route
      path={RoutePath.jamsadrRules}
      element={
        <CustomRedirect url="http://www.jamsadr.com/rules-comprehensive-arbitration/" />
      }
    />,
  ])
);

const AppRouter = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default AppRouter;
