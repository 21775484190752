import React from "react";
// import android from "../../assests/icons/i_android.png";

// import play_store_btn from "assets/play_store.png";
import play_store_btn from "assets/icons/i_google.svg";
// import apple_store_btn from "assets/apple_store.png";
import apple_store_btn from "assets/icons/i_apple.svg";
// import preview from "assets/utils/preview.svg";
import preview from "assets/images/preview3x.png";
import union from "assets/utils/union.svg";
import decLeft from "assets/icons/i_dec_left.svg";
import decRight from "assets/icons/i_dec_right.svg";
import { motion } from "framer-motion";
// import union from "assets/union.png";

const Hero = () => {
  return (
    <div className="bg-sgrey-50 text-white overflow-clip">
      <div className="clip bg-gradient pt-40 lg:pt-48 relative overflow-hidden">
        <motion.div
          initial={{ y: "-150%" }}
          animate={{ y: "0px" }}
          transition={{ duration: 0.6 }}
          className="w-[80%] mx-auto text-center relative z-[1] max-w-[450px] "
        >
          <h2 className="stext-heading-top text-white pb-5 max-w-[400px]">
            Instant low-cost fiat cross-border payments
          </h2>
          <p className="stext-base px-2 pb-3">
            Borderless payments made easy. Send and receive payments
            internationally under ten (10) minutes and in your local currencies
          </p>

          <div className="flex justify-center space-x-3 w-[75%] mx-auto py-5 mb-[10rem] md:mb-[14rem]">
            <img src={play_store_btn} alt="play-store" className="" />
            <img src={apple_store_btn} alt="apple store" className="" />
          </div>
        </motion.div>
        <div className="absolute bottom-[55px] h-[250px] max-w-[700px] left-[-5%] right-[-5%] mx-auto md:h-[300px]">
          <img src={union} alt="preview" className="absolute z-0" />

          <img
            src={decLeft}
            alt="preview"
            className="absolute z-[1] left-[-30px] w-[300px] bottom-[-7rem] md:bottom-[-9rem] md:w-auto"
          />
          <img
            src={decRight}
            alt="preview"
            className="absolute z-[1] right-[-30px] w-[300px] bottom-[-7rem] md:bottom-[-9rem] md:w-auto"
          />
        </div>
      </div>
      <motion.div
        initial={{ y: "150%", position: "" }}
        animate={{ y: "0px" }}
        transition={{ duration: 0.6 }}
        className="relative z-[2]"
      >
        <img
          src={preview}
          alt="preview"
          className="mt-[-140px] w-[85%] mx-auto z-[3] relative max-w-[550px] md:mt-[-180px] pb-28"
        />
      </motion.div>
      {/* <div className='bg-red-500 h-[200px] w-full'></div> */}
    </div>
  );
};

export default Hero;
