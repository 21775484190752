import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import closeIcon from "assets/icons/close-modal.svg";
import { WaitListForm } from "components/form";

const WaitList = ({
	isOpen,
	handleClose,
	setIsError,
	handleOpenStatusModal
}) => {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-[999999]"
				onClose={handleClose}
			>
				<div className="min-h-screen text-center">
					<Dialog.Overlay className="fixed left-0 top-0 h-full w-full bg-black bg-opacity-75 z-[999999]" />
					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="inline-block h-screen align-middle"
						aria-hidden="true"
					>
					
						&#8203;
					</span>
					<div className="inline-block w-11/12 text-left align-middle transition-all z-[999999999] relative shadow-xl bg-white max-w-lg px-6 pt-[41px] pb-6 rounded-[10px] h-[100dvh] overflow-auto">
						<Dialog.Title
							as="div"
							className="flex justify-between items-center"
						>
							<img
								src={closeIcon}
								alt=""
								className="cursor-pointer mr-auto"
								onClick={handleClose}
							/>
						</Dialog.Title>
						<section className="my-6">
							<div className="mx-auto w-fit flex flex-col items-center">
								<h3 className="text-sprimary-grad2 myriad-bold mt-4 text-center text-2xl md:text-[32px] leading-[29px] md:leading-[38px] mb-6">
									Join our waitlist
								</h3>
								<p className="myriad-regular text-sm md:text-base leading-[17px] md:leading-6 text-sgrey-100 text-center md:w-[324px]">
									Be the first to know when we go live. We’ll send you exclusive
									updates
								</p>
							</div>
							<div className="my-10">
								<WaitListForm
									{...{ handleClose, setIsError, handleOpenStatusModal }}
								/>
								
							</div>
						</section>
						<div>
							<p className="text-center text-base text-sgrey-100 myriad-regular">
								© 2022 Skitpay. All rights reserved.
							</p>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default WaitList;
