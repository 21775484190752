import one from 'assets/last/1.svg'
import two from 'assets/last/2.svg'
import three from 'assets/last/3.svg'

export const lastData = [
    {
        heading:'App to bank Payments',
        description: 'Use Skitpay to make international automated app to bank payments in your local currency ',
        image: one,
    },
    {
        heading:'Cutting edge Technology',
        description: 'Our technology leverages Central Bank Digital Currencies to make international payments easier, faster & more secured',
        image: two,
    },
    {
        heading:'Universal Access',
        description: 'Skitpay is designed to be easily accessible   to individuals, businesses, and financial institutions globally',
        image: three,
    },
]