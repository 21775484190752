import React from "react";
import privacy_asset from "assets/images/privacy_asset.png";
import privacy_asset_mobile from "assets/images/privacy_asset_mobile.png";
import { Link } from "react-router-dom";
import RoutePath from "router/route_path";
import useMediaQuery from "hooks/useMediaQuery";
import HeadingWithContent from "pages/privacy_policy/components/heading_wth_content";
import { termsOfUse } from "utils/data/terms_of_use/terms_of_use";
const TermsOfUse = () => {
  const mediaQuery = useMediaQuery();
  const isLargeScreen = mediaQuery.width >= 786;
  return (
    <>
      <section className="h-[80vh] max-h-[550px] bg-gradient pt-40 lg:pt-48">
        <div className="px-4 text-white relative md:px-16">
          <div className="md:w-[85%] mx-auto ">
            <p className="text-sm font-myriadProSemibold">
              Current as of 1 May 2023
            </p>
            <h1 className="font-myriadProBold text-4xl pt-3 md:text-6xl">
              Terms of use
            </h1>
            <p className="leading-[19px] pt-2 md:max-w-[55%]">
              By accessing our website, you are agreeing to be bound by these
              terms of service, all applicable laws and regulations, and agree
              that you are responsible for compliance with any applicable local
              laws.
            </p>
          </div>
          <div className="w-full overflow-clip mt-16 grid place-items-center  h-[300px] mx-auto  md:relative ">
            <img
              src={isLargeScreen ? privacy_asset : privacy_asset_mobile}
              alt="privacy-asset"
              className="h-full w-full object-cover  md:w-full"
            />
          </div>
        </div>
      </section>
      <section className="px-6 pt-[230px] pb-[100px] text-sgrey-500 md:pt-[260px] max-w-[900px] mx-auto">
        <p>
          The website located at{" "}
          <Link className="underline text-sprimary" to={RoutePath.home}>
            https://www.skitpay.com/
          </Link>{" "}
          (the “Site”) is a copyrighted work belonging to Skitpay Inc
          (“Company”, “us”, “our”, and “we”). Certain features of the Site may
          be subject to additional guidelines, terms, or rules, which will be
          posted on the Site in connection with such features. All such
          additional terms, guidelines, and rules are incorporated by reference
          into these Terms.
          <span className="block pt-3 lowercase first-letter:uppercase">
            THESE TERMS OF USE (THESE “TERMS”) SET FORTH THE LEGALLY BINDING
            TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE. BY ACCESSING
            OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF
            YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND
            WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER
            INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
            REPRESENT). YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS
            IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL
            OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.
          </span>
          <span className="block pt-3">
            Please be aware that section 8.2 of these terms contains provisions
            governing how disputes that you and we have against each other are
            resolved, including, without limitation, any disputes that arose or
            were asserted prior to the effective date of your acceptance of
            these terms. in particular, it contains an arbitration agreement
            which will, with limited exceptions, require disputes between us to
            be submitted to binding and final arbitration. unless you opt out of
            the arbitration agreement: (1) you will only be permitted to pursue
            disputes or claims and seek relief against us on an individual
            basis, not as a plaintiff or class member in any class or
            representative action or proceeding; and (2) you are waiving your
            right to pursue disputes or claims and seek relief in a court of law
            and to have a jury trial.
          </span>
        </p>
        {termsOfUse.map((policy) => {
          return (
            <HeadingWithContent
              key={policy.heading}
              className="pt-8"
              heading={policy.heading}
              introText={policy.introText}
              contents={policy.contents}
            />
          );
        })}
        {/* <HeadingWithContent className="pt-8" /> */}
      </section>
    </>
  );
};

export default TermsOfUse;
