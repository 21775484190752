import React, { useState } from "react";
import useMediaQuery from "hooks/useMediaQuery";
import privacy_asset from "assets/images/privacy_asset.png";
import privacy_asset_mobile from "assets/images/privacy_asset_mobile.png";
import { Link } from "react-router-dom";
import RoutePath from "router/route_path";
import { SIconTick } from "assets/icons";
import { sendContactMessage } from "api/api";
import CustomForm from "components/custom_form/form/custom_form";
import FormVaidation from "utils/FormValidation";
import useFormState from "components/custom_form/form/form_state";
import CustomInput from "components/custom_form/form/custom_input";
import { WaitListStatus } from "components/modal";
const ContactPage = () => {
  const mediaQuery = useMediaQuery();
  const isLargeScreen = mediaQuery.width >= 786;
  const formStateProps = useFormState();
  const { validateForm, values, clearForm } = formStateProps;

  const [isLoading, setisLoading] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [isError, setisError] = useState(false);
  const [isModalShown, setisModalShown] = useState(false);

  const onSubmit = async () => {
    // console.log(values);
    // // console.log(formStateProps);
    if (!validateForm()) {
      return;
    }
    setisLoading(true);

    try {
      const response = await sendContactMessage(values);
      if (response.data.status === "success") {
        setisLoading(false);
        // console.log("success");
        clearForm();
        setisModalShown(true);
        setisError(false);
      } else {
        setisLoading(false);
        setisModalShown(true);
        setisError(true);
      }
    } catch (error) {
      // console.log(error);

      setisModalShown(true);
      setisError(true);
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <WaitListStatus
        isOpen={isModalShown}
        isError={isError}
        handleClose={() => setisModalShown(false)}
        errorMessage="An error occured taking your response at the moment, please try again later"
        successMessage="successfully recorded your response, we wil get back to you ASAP"
      />
      <section className="h-[80vh] max-h-[550px] bg-gradient pt-40 lg:pt-48">
        <div className="px-4 text-white relative md:px-16">
          <div className="md:w-[85%] mx-auto ">
            <p className="text-sm font-myriadProSemibold">Contact us</p>
            <h1 className="font-myriadProBold text-4xl pt-3 md:text-6xl">
              Let’s level up your brand, together
            </h1>
            <p className="leading-[19px] pt-2 md:max-w-[55%]">
              We’d love to hear from you. Please fill out this form.
            </p>
          </div>
          <div className="w-full overflow-clip mt-16 grid place-items-center  h-[300px] mx-auto  md:relative ">
            <img
              src={isLargeScreen ? privacy_asset : privacy_asset_mobile}
              alt="privacy-asset"
              className="h-full w-full object-cover  md:w-full"
            />
          </div>
        </div>
      </section>
      <section className="px-6 pt-[230px] pb-[100px] text-sgrey-500 md:pt-[260px] max-w-[700px] mx-auto">
        <CustomForm formStateProps={formStateProps} className="space-y-[24px]">
          <CustomInput
            title="First name"
            name="firstName"
            validator={FormVaidation.validateSingleName}
          />
          <CustomInput
            title="Last name"
            name="lastName"
            validator={FormVaidation.validateSingleName}
          />

          <CustomInput
            title="Email"
            name="email"
            validator={FormVaidation.validateEmail}
          />
          <CustomInput
            title="Phone number"
            name="phone"
            validator={FormVaidation.validatePhone}
          />

          <CustomInput
            title="Message"
            inputType="textArea"
            rows={4}
            name="message"
            validator={FormVaidation.validateMessage}
          />
        </CustomForm>
        <div className="flex space-x-[12px] items-center mt-10">
          <CheckBox
            onChanged={(val) => {
              setisChecked(val);
            }}
          />
          <p>
            You agree to our friendly{" "}
            <Link
              to={RoutePath.privacyPolicy}
              className="font-myriadProSemibold text-sprimary underline "
            >
              privacy policy
            </Link>
          </p>
        </div>

        <button
          className={` h-12  text-white w-full mt-8 rounded-lg text-base font-myriadProSemibold transition-all  grid place-items-center ${
            isChecked ? "active:scale-95 bg-slilac" : "bg-slate-400"
          }`}
          onClick={isChecked ? onSubmit : () => {}}
        >
          {isLoading ? (
            <div className="w-8 h-8 border-2 rounded-full animate-spin border-r-transparent"></div>
          ) : (
            "Send Message"
          )}
        </button>
      </section>
    </>
  );
};

export default ContactPage;

/**
 *
 * @param {object} param
 * @param {(string)=>{}} param.onChanged
 * @returns
 */
const CheckBox = ({ onChanged }) => {
  const [isSelected, setisSelected] = useState(false);

  return (
    <button
      className={`w-5 h-5 p-1 rounded-md border border-slilac transition-colors duration-300 ${
        isSelected && "bg-sprimary"
      }`}
      onClick={() => {
        onChanged(!isSelected);
        setisSelected(!isSelected);
      }}
    >
      {isSelected && <SIconTick className="text-white" />}
    </button>
  );
};
